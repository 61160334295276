<template>
  <div />
</template>

<script type="text/babel">
export default {
  name: "empty",
  components: {}
};
</script>
