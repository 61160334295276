<template>
  <form
    ref="form"
    method="POST"
    :action="`${authPortal}/uitloggen`"
    style="display: none"
  ></form>
</template>

<script type="text/babel">
import store from "@/store";

export default {
  name: "logout-view",
  async mounted() {
    await store.dispatch("auth/logout");
    this.$refs.form.submit();
  },
  data: () => ({
    authPortal: process.env.VUE_APP_AUTH_PORTAL
  })
};
</script>
